import { useState, useEffect, useLayoutEffect } from 'react';

import './css/reset.css';
import './scss/App.scss';

import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';

import prefecturesData from './prefectures.json';

function App() {
  const {register, handleSubmit, reset, getValues, clearErrors, formState: {errors}} = useForm();
  const [inputState, setInputState] = useState('prefectures'); // 入力の進捗情報
  const [prefectures, setPrefectures] = useState(''); // 都道府県 value
  const [address1, setAddress1] = useState(''); // 都道府県
  const [address2, setAddress2] = useState(''); // 市区町村
  const [gasChargesData, setGasChargesData] = useState(null); // ガス料金情報
  const [municipalitiesData, setMunicipalitiesData] = useState(null);
  const [municipalitiesDisabled, setMunicipalitiesDisabled] = useState(true); // 市区町村 input disabled 属性設定
  const [addressError, setAddressError] = useState({
    inputAdd1: false,
    inputAdd2: false
  });
  const [priceState, setPriceState] = useState({
          price: 0,
          meter: 0
        });
  const [comparisonData, setComparisonData] = useState({
          price1: 0,
          price2: 0,
          price3: 0,
          serviceArea: null
        });
  const [resultState, setResultState] = useState({
    message: '',
    //unitPrice: 0,
    flag: false,
    compareButton: true
  });
  const getData = async () => {
    // zenko-sai-test でないと https://tk-energy.biz/ からのfetchを許可していません
    //const response = await fetch('https://kikuopocket.com/zenko-sai-test/compare/') // ローカル作業用
    //const response = await fetch('../compare/') // testディレクトリ用
    const response = await fetch('./compare/') // 本番用
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        setGasChargesData(data);
      })
      .catch(error => {
        console.error('getData error: ', error);
      });
    return response;
  };
  //const test = () => console.log('sampleData: ', sampleData[0]['price_1']); // 確認に使用
  const handlePrefecturesSelect = (event) => { // 都道府県入力完了
    const selectedId = parseFloat(event.target.value);
    const selectedRow = prefecturesData.filter(current => current.id === selectedId);
    if(selectedRow[0].service_area === 'no'){
      clearErrors('inputAdd1'); // エラーを解除
      setPrefectures(selectedRow[0].id);
      setAddress1(selectedRow[0].value);
      setAddress2('');
      setMunicipalitiesData(null);
      setMunicipalitiesDisabled(true);
      setResultState({
        message: '',
        //unitPrice: resultState.unitPrice,
        flag: false,
        compareButton: false
      });
      return;
    }
    if(selectedRow[0].value){
      clearErrors('inputAdd1'); // エラーを解除
      const selectData = gasChargesData.filter(current => current.address_1 === selectedRow[0].value);
      setAddressError({
        inputAdd1: false,
        inputAdd2: false
      });
      if(selectData){
        setMunicipalitiesData(selectData);
      }else{
        setMunicipalitiesData(null);
      }
      setAddress2(''); // 市区町村リセット
      setInputState('municipalities'); // 市区町村入力
      setMunicipalitiesDisabled(false); // inputのdisabled属性設定
      setPrefectures(selectedRow[0].id);
      setAddress1(selectedRow[0].value);
      setResultState({
        message: resultState.message,
        //unitPrice: resultState.unitPrice,
        flag: resultState.flag,
        compareButton: true
      });
      //console.log('Selected prefectures: ', selectedRow[0].value);
    }
  };
  const handleMunicipalitiesSelect = (event) => { // 市区町村入力完了
    const selectValue = event.target.value;
    setAddress2(selectValue);
    const match = municipalitiesData.filter(current => current.address_2 === selectValue);
    if(match){
      clearErrors('inputAdd2'); // エラーを解除
      setComparisonData({ // 比較情報セット
        price1: match[0].price_1,
        price2: match[0].price_2,
        price3: match[0].price_3,
        serviceArea: match[0].service_area
      });
      setAddressError({
        inputAdd1: false,
        inputAdd2: false
      });
      if(match[0].service_area === 'yes'){
        setResultState({
          message: resultState.message,
          //unitPrice: resultState.unitPrice,
          flag: resultState.flag,
          compareButton: true
        });
      }
      if(match[0].service_area === 'no'){
        setResultState({
          message: '',
          //unitPrice: resultState.unitPrice,
          flag: false,
          compareButton: false
        });
      }
      setInputState('completed');
    }
  };
  const allReset = () => {
    setInputState('prefectures'); // 入力の進捗情報
    setPrefectures('');
    setAddress1('');
    setAddress2('');
    setMunicipalitiesData(null);
    setMunicipalitiesDisabled(true);
    setPriceState({
      price: 0,
      meter: 0
    });
    setComparisonData({
      price1: 0,
      price2: 0,
      price3: 0,
      serviceArea: null
    });
    setResultState({
      message: '',
      //unitPrice: 0,
      flag: false,
      compareButton: true
    });
    reset({
      inputPrice: '',
      inputMeter: '',
      //inputAdd1: '',
      //inputAdd2: ''
    });
    setAddressError({
      inputAdd1: false,
      inputAdd2: false
    });
    //console.log('allReset?!');
  };
  const compareButtonClick = () => {
    const inputAdd1 = getValues('inputAdd1');
    const inputAdd2 = getValues('inputAdd2');
    if(inputAdd1 === 0 && !address1){
      setAddressError({
        inputAdd1: true,
        inputAdd2: false
      });
    }else if(inputAdd2 === 0 && !address2){
      setAddressError({
        inputAdd1: false,
        inputAdd2: true
      });
    }else{
      setAddressError({
        inputAdd1: false,
        inputAdd2: false
      });
    }
  };
  const comparePricesSubmit = inputData => { // 料金を比較する
    //console.log('inputData:', inputData);
    const inputPrice = parseFloat(inputData.inputPrice); // 入力利用料金
    const inputMeter = parseFloat(inputData.inputMeter); // 入力利用量
    //const comparisonPrice1 = parseFloat(comparisonData.price1); // 全国平均基本料金
    const comparisonPrice2 = parseFloat(comparisonData.price2); // 共済単価
    const comparisonPrice3 = parseFloat(comparisonData.price3); // 共済基本料金 ※現状全て1600
    if(inputPrice > 0 && inputMeter > 0){ // とりあえず1以上
      setPriceState({ // 入力値をセット
        price: inputPrice,
        meter: inputMeter
      });
    }
    //console.log(inputPrice);
    //console.log(inputMeter);
    //console.log(comparisonData.price1);
    //console.log(comparisonData.price2);
    //console.log(comparisonData.price3);
    if(inputPrice > 0 && inputMeter > 0 && comparisonPrice2 > 0 && comparisonPrice3 > 0){ // とりあえず1以上
      //const unitPrice = Math.round((inputPrice - comparisonPrice1) / inputMeter); // お客様の現状の単価
      const nowYearPrice = inputPrice * 12; // 現状の想定年間料金?
      const zenkoSaiYearPrice = (comparisonPrice2 * inputMeter + comparisonPrice3) * 12; // 共済の想定年間料金?
      const difference = nowYearPrice - zenkoSaiYearPrice;
      let message = '';
      if(difference >= 25000){
        message = <>年間で<em>25,000</em>円以上お安くなる可能性があります。</>
      }else if(difference >= 20000 && difference < 25000){
        message = <>年間で<em>20,000</em>円～<em>25,000</em>円安くなる可能性があります。</>
      }else if(difference >= 15000 && difference < 20000){
        message = <>年間で<em>15,000</em>円～<em>20,000</em>円安くなる可能性があります。</>;
      }else if(difference >= 9000 && difference < 15000){
        message = <>年間で<em>9,000</em>円～<em>15,000</em>円安くなる可能性があります。</>;
      }else if(difference >= 6000 && difference < 9000){
        message = <>年間で<em>6,000</em>円～<em>9,000</em>円安くなる可能性があります。</>;
      }else if(difference >= 3000 && difference < 6000){
        message = <>年間で<em>3,000</em>円～<em>6,000</em>円安くなる可能性があります。</>;
      }else if(difference >= 0 && difference < 3000){
        message = <>年間で<em>0</em>円～<em>3,000</em>円安くなる可能性があります。</>;
      }else{
        message = <>現在お得な料金で契約中です。</>;
      }
      /*if(unitPrice >= comparisonPrice2){
        const cheaper = unitPrice * inputMeter * 12 - comparisonPrice2 * inputMeter * 12; // 年間料金の差額計算
        message = <>全厚済ガスでは、<strong>安く</strong>なります。<br /><span className='spaceCut-01'>（参考）</span><strong>年間<em>{new Intl.NumberFormat('ja-JP').format(cheaper)}</em>円</strong>&nbsp;安くなる可能性があります。</>;
      }*/
      setResultState({
        message: message,
        //unitPrice: unitPrice,
        flag: true,
        compareButton: resultState.compareButton
      });
    }
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  useEffect(() => { // 入力の進捗情報
    //console.log('inputState: ', inputState);
  }, [inputState]);
  useEffect(() => {
    //prefectures && console.log('prefectures: ', prefectures);
    //comparisonData.price1 !== 0 && comparisonData.price2 !== 0 
      //&& (console.log('comparisonData: ',comparisonData));
    //comparisonData.serviceArea && console.log('serviceArea: ', comparisonData.serviceArea);
  }, [comparisonData]);
  useEffect(() => {
    //address1 && console.log('address1: ', address1);
  }, [address1]);
  useEffect(() => {
    const prefecturesConfirm = (current) => {
      let filtered = gasChargesData.filter(
        item => item.address_1 === current.value && item.service_area === 'yes'
      );
      if(!filtered.length){
        //console.log('filtered: ', current.value);
        current.service_area = 'no';
      }
    };
    if(prefecturesData && gasChargesData){
      prefecturesData.map(prefecturesConfirm);
      //console.log('prefecturesData: ', prefecturesData);
    }
  }, [gasChargesData]);
  useEffect(() => {
    //priceState.price !== 0 && console.log('priceState.price: ', priceState.price);
    //priceState.meter !== 0 && console.log('priceState.meter: ', priceState.meter);
  }, [priceState]);
  useEffect(() => {
    const replaceStr = 'ご利用料金<em>（税込み）</em>'; // カッコの空きを調整
    const selectElem1 = document.querySelectorAll('#price-input-area label.MuiInputLabel-root');
    const selectElem2 = document.querySelectorAll('#price-input-area .MuiOutlinedInput-root fieldset span');
    selectElem1[0].innerHTML = replaceStr;
    selectElem2[0].innerHTML = replaceStr;
    //console.log('text: ', selectElem1[0].childNodes[0]);
  }, []);
  return (
    <div className="app">
      <section className='content-wrap'>
        <form onSubmit={handleSubmit(comparePricesSubmit)}>
          <div className="input-area" id="price-input-area">
            <TextField
              id="price"
              label="ご利用料金"
              variant="outlined"
              type="number"
              InputProps={{endAdornment: <InputAdornment position="end">円</InputAdornment>}}
              className="font-numbers"
              fullWidth
              {...register(
                'inputPrice', 
                {
                  required: true, 
                  pattern: /^\d+$/,
                  //min: 2013
                }
              )}
            />
            {errors.inputPrice?.type === 'required' && <p className="error-msg">ご利用料金を入力して下さい</p>}
            {errors.inputPrice?.type === 'pattern' && <p className="error-msg">ご利用料金は半角数字で入力して下さい</p>}
            {
            //errors.inputPrice?.type === 'min' && <p className="error-msg">ご利用料金は2,013円以上を入力して下さい</p>
            }
          </div>
          <div className="input-area">
            <TextField
              id="meter"
              label="ご利用量"
              variant="outlined"
              //type="number"
              InputProps={{endAdornment: <InputAdornment position="end">m&sup3;</InputAdornment>}}
              className="font-numbers"
              fullWidth
              {...register(
                'inputMeter', 
                {
                  required: true, 
                  pattern: /^\d+(\.\d+)?$/, 
                  min: 0.1
                }
              )}
            />
            {errors.inputMeter?.type === 'required' && <p className="error-msg">ご利用量を入力して下さい</p>}
            {errors.inputMeter?.type === 'pattern' && <p className="error-msg">ご利用量は半角数字で入力して下さい</p>}
            {errors.inputMeter?.type === 'min' && <p className="error-msg">ご利用量は0.1m&sup3;以上を入力して下さい</p>}
          </div>
          <div className="input-area">
            <FormControl fullWidth>
              <InputLabel id="select-prefectures-label">都道府県</InputLabel>
              <Select
                labelId="select-prefectures-label"
                id="select-prefectures"
                value={prefectures}
                label="都道府県"
                onChange={handlePrefecturesSelect}
              >
                {prefecturesData.map((item) => (
                  <MenuItem value={item.id} key={item.id} {...register('inputAdd1', {required: true})}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(errors.inputAdd1?.type === 'required' || addressError.inputAdd1) && 
              <p className="error-msg">都道府県を選択して下さい</p>
            // React Hook Form のバリデーションが正常に機能しない場合があるので
            // addressError.inputAdd1 を追加
            }
          </div>
          <div className="input-area">
            <FormControl fullWidth disabled={municipalitiesDisabled}>
              <InputLabel id="select-municipalities-label">市区町村</InputLabel>
              <Select
                labelId="select-municipalities-label"
                id="select-municipalities"
                value={address2}
                label="市区町村"
                onChange={handleMunicipalitiesSelect}
              >
                {municipalitiesData && municipalitiesData.map((item, index) => (
                  <MenuItem value={item.address_2} key={index} {...register('inputAdd2', {required: true})}>
                    {item.address_2}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(errors.inputAdd2?.type === 'required' || (!addressError.inputAdd1 && addressError.inputAdd2)) && 
              <p className="error-msg">市区町村を選択して下さい</p>
            // React Hook Form のバリデーションが正常に機能しない場合があるので
            // (!addressError.inputAdd1 && addressError.inputAdd2) を追加
            }
          </div>
          {resultState.compareButton && !resultState.flag 
            && (<Stack spacing={1} direction="row" className="button-area-01"><Button variant="contained" type="submit" onClick={compareButtonClick}>ガス料金を診断する</Button></Stack>)}
          {(resultState.flag || !resultState.compareButton) && 
            <Stack spacing={1} direction="row" className="button-area-01">
              <Button variant="outlined" size="large" onClick={allReset} className="reset">リセット</Button>
              <Button variant="contained" href='https://tk-energy.biz/gas/contact/' className="link-01">お問い合わせ</Button>
              {resultState.compareButton 
                ? <Button variant="contained" href='https://tk-energy.biz/gas/apply/' className="link-02">お申し込み</Button>
                : <Button variant="contained" href='https://tk-energy.biz/gas/kari/' className="link-02"><span className="mgCut-01">（仮）</span>お申し込み</Button>}
            </Stack>}
          {!resultState.compareButton && 
            <>
              <div className='notice-01'>
                <p>
                  {address1}{address2}
                  {(address1 || address2) && <>は、</>}
                  現在サービス提供エリア外です。<br />サービスエリア拡大後、ご連絡させて頂きます。<br /><span className='mgCut-01'>（仮）</span>お申し込みフォームよりお問合せ下さい。
                </p>
              </div>
            </>}
          {resultState.flag && <div className="result-area">
            <strong className='title'>診断結果</strong>
            <p className='message-01'>{resultState.message}</p>
            <div className='notes-01'>
              <div>
                <p>表示金額は、参考価格であり、提供金額をお約束するものではございません。</p>
                <p>契約内容・環境・条件によっては、全厚済ガスサービスを提供できない場合がございます。</p>
              </div>
            </div>
            {
            //console.log('1立方メートルの単価', resultState.unitPrice)
            //<p>参考情報&nbsp;:&nbsp;1立方メートルの単価&nbsp;{resultState.unitPrice}&nbsp;円</p>
            }
          </div>}
        </form>
      </section>
    </div>
  );
}

export default App;
